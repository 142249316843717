import React, { useEffect } from "react";
import { Modal, ModalBody } from "reactstrap";
import "./indexTemplate.css";
import "../../index.css";
import { useSelector } from "react-redux";
import './indexTemplate.css'

const UpLoadingModal = ({ modalColor }) => {
  const VerificationUrl = useSelector(
    (state) => state.verificationLinkPOC.verificationPocData.verificationlink
  );
  const parsedUrl = new URL(VerificationUrl);
  const pathname = parsedUrl.pathname;
  const cleanPathname = pathname.startsWith("/") ? pathname.slice(1) : pathname;
  const lastLinkedClickedData = useSelector(state => state?.lastLinkClicked?.dataSuccess)
  const sessionId = lastLinkedClickedData?.inviteid

  // useEffect(() => {
  //   if (VerificationUrl) {
  //     
  //     window.location.href = cleanPathname; // Redirect to the verification URL
  //   }
  // }, [cleanPathname,VerificationUrl]);

  return (
    <Modal
      isOpen={true}
      className="modal-centered flex "
      style={{
        fontFamily: "Montserrat",
        "--modal-color": modalColor,
        maxWidth: "25rem"
      }}
      keyboard={false}
      backdrop={true}
      backdropClassName="custom-backdrop"
    >
      <ModalBody className="text-center">
        <div className="loading mt-3 mb-2">
          <div className="lds-ellipsis">
            <div
              style={{
                background: modalColor
                  ? "var(--modal-color)"
                  : "var(--default-color)",
              }}
            ></div>
            <div
              style={{
                background: modalColor
                  ? "var(--modal-color)"
                  : "var(--default-color)",
              }}
            ></div>
            <div
              style={{
                background: modalColor
                  ? "var(--modal-color)"
                  : "var(--default-color)",
              }}
            ></div>
            <div
              style={{
                background: modalColor
                  ? "var(--modal-color)"
                  : "var(--default-color)",
              }}
            ></div>
          </div>
        </div>
        <h5
          className="mb-3 fw-bold "
          style={{ color: modalColor, lineHeight: "1", letterSpacing: "2px" }}
        >
          Uploading...
        </h5>
      </ModalBody>
      <p style={{marginLeft:"85%",fontSize:"10px",marginTop:"1rem",marginBottom:"10px", width:"5rem",marginRight:"10rem"}}>{sessionId}</p>
    </Modal>
  );
};

export default UpLoadingModal;
