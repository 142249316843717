import React, { useEffect, useRef, useState } from "react";
import Uploader from "../../Components/newTemplates/uploader";
import { useDispatch, useSelector } from "react-redux";
import { VerificationLinkPoc } from "../../actions/VerificationLinkPoc";
import { unsupportedMedia, uploadFileAction } from "../../actions/Uploadaction";
import { liveFeedback } from "../../actions/liveFeddback";
import { updateSession } from "../../helpers/updateSessionHandler";
import Upload from "../../Components/upload";
import PleaseWait from "../../Components/templates/waiting.template";
import UploadStatus from "../../Components/uploadStatus";
import ThankYouModal from "../../Components/templates/thankyou.template";
import WrongDocument from "../../Components/newTemplates/wrongDocument";
import UnsupportedMedia from "../../Components/templates/unsupported_file.template";
import {
  BUTTONID_IS_NULL,
  FILE_ALREADY_EXISTS,
  ONESHOTUPLOAD_FAILURE,
  ONESHOTUPLOAD_START,
  ONESHOTUPLOAD_SUCCESS,
  SOMETHING_WENT_WRONG,
} from "../../actions/actionTypes";
import { sendLogs } from "../../helpers/sendLogs";
import axios from "axios";
import { env as environment } from "../../environments";
import {
  PdfToBase64,
  b64toBlob,
  blobToFile,
} from "../../helpers/pdfFileChangerHandler";
import DocumentNotOriginal from "../../Components/newTemplates/DocumentNotOriginal";
import ButtonIdNotFound from "../../Components/newTemplates/ButtonIdNotFound";
import SomethingWentWrong from "../../Components/newTemplates/somethingWentWrong";
import PasswordProtected from "../../Components/newTemplates/passwordProtected";

export default function Wrapper() {
  const [params, setParams] = useState({});
  const [allFiles, setAllFiles] = useState([]);

  // Get already saved liveFeedback responses from store.
  const allLiveFeedbackResponses = useSelector(
    (state) => state.livefeedback?.allLivefeedbackResponses
  );

  // Check if document is invalid
  const livefeedbackInvalidDoc = useSelector(
    (state) => state.livefeedback?.livefeedbackInvalidDoc
  );

  const somethingWentWrong = useSelector(
    (state) => state.upload?.somethingWentWrong
  );

  const lastLinkClickedData = useSelector(
    (state) => state.lastLinkClicked?.dataSuccess
  );

  const isVerifying = useSelector((state) => state?.upload?.isVerifying);

  const processingLivefeedback = useSelector(
    (state) => state?.livefeedback?.processingLivefeedback
  );

  const processingOneShotUpload = useSelector(
    (state) => state?.oneShotUpload?.oneShotStart
  );

  const oneShotUploadSuccessResponse = useSelector(
    (state) => state?.oneShotUpload?.oneShotSuccess
  );

  const passwordProtected = useSelector(
    (state) => state?.upload?.passwordProtected
  );

  

  

  const documentIsVerified = useSelector(
    (state) => state?.upload?.documentIsVerified
  );

  const documentIsWrong = useSelector(
    (state) => state?.upload?.documentIsWrong
  );
  
  const isUnsupportedMedia = useSelector(
    (state) => state?.upload?.unsupportedMedia
  );

  const fileAlreadyExists = useSelector(
    (state) => state?.upload?.fileAlreadyExists
  );

  const documentIsNotOriginal = useSelector(
    (state) => state?.upload?.documentIsNotOriginal
  );

  const buttonIdIsNull = useSelector((state) => state?.upload?.buttonIdIsNull);

  const errorInVerificationPoc = useSelector(
    (state) => state?.verificationLinkPOC?.error
  );

  

  const dispatch = useDispatch();

  // Function to upload file from system.
  const handleFileSelect = async (event) => {
    
    event.preventDefault();
    const file = event.target.files[0] || event.dataTransfer.files[0];

    

    var notSupportedDocument = {
      data: {
        indicator: {
          progress_no: "6",
          progress_name: "uploaded document not supported",
          Detection: "upload",
        },
      },
    };
    if (file && file.type === "application/pdf") {
      

      console.log(
        "all files file already exists",
        allFiles.find((item) => item.name === file.name)
      );

      // if file already exists already, show already exists popup and for the same dispatch the below action.
      if (allFiles.find((item) => item.name === file.name)) {
        
        dispatch({ type: FILE_ALREADY_EXISTS, payload: true });
      }
      // if file is new i.e. doesn't exists already, do further process.
      else {
        dispatch(
          uploadFileAction(
            file,
            params,
            lastLinkClickedData.category,
            lastLinkClickedData.sessionid
          )
        );
        setAllFiles([...allFiles, ...event.target.files]);
        dispatch(
          liveFeedback(
            file,
            lastLinkClickedData.category,
            lastLinkClickedData.sessionid
          )
        );
      }
    } else {
      
      dispatch(unsupportedMedia(true));
      if (params.buttonid) {
        dispatch(
          updateSession(notSupportedDocument, lastLinkClickedData.sessionid)
        );
      }
    }
    event.target.value = null;
  };

  const handleBrowseClick = () => {
    // Trigger click event on file input
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const fileInputRef = useRef(null);

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  // Function to drag & drop file from system.
  const handleDrop = (event) => {
    var notSupportedDocument = {
      data: {
        indicator: {
          progress_no: "6",
          progress_name: "uploaded document not supported",
          Detection: "upload",
        },
      },
    };
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    
    if (file && file.type === "application/pdf") {
      // if file already exists already, show already exists popup and for the same dispatch the below action.
      if (allFiles.find((item) => item.name === file.name)) {
        
        dispatch({ type: FILE_ALREADY_EXISTS, payload: true });
      }

      // if file is new i.e. doesn't exists already, do further process.
      else {
        dispatch(
          uploadFileAction(
            file,
            params,
            lastLinkClickedData.category,
            lastLinkClickedData.sessionid
          )
        );

        setAllFiles([...allFiles, ...event.dataTransfer.files]);
        dispatch(
          liveFeedback(
            file,
            lastLinkClickedData.category,
            lastLinkClickedData.sessionid
          )
        );
      }
    } else {
      dispatch(unsupportedMedia(true));
      if (params.buttonid) {
        dispatch(
          updateSession(notSupportedDocument, lastLinkClickedData.sessionid)
        );
      }
    }
  };

  useEffect(() => {
    const newParams = {};
    if (environment["env"] === "CDN") {
      newParams["buttonid"] = environment["buttonId"];
      newParams["trackId"] = environment["trackId"];
      setParams(newParams);
    } else {
      const queryParams = new URLSearchParams(window.location.search);
      for (const [key, value] of queryParams) {
        newParams[key] = value;
      }
      if (Object.keys(newParams).length > 0) {
        setParams(newParams);
      }
    }
  }, []);

  useEffect(() => {
    // if (params) {
    //   dispatch(VerificationLinkPoc(params));
    // }
    if (params && params.hasOwnProperty("buttonid") && params.buttonid) {
      dispatch(VerificationLinkPoc(params));
    } else if (params.hasOwnProperty("buttonid") && !params.buttonid) {
      dispatch({ type: BUTTONID_IS_NULL });
    }
  }, [params]);

  const uploadDoneSession = {
    sessionid: lastLinkClickedData?.sessionid,
    data: {
      indicator: {
        progress_no: "8",
        progress_name: "Done, now in Review ",
        Detection: "upload",
      },
    },
  };

  const oneShotUploadAction = async () => {
    const oneShotPayload = {
      mxid: lastLinkClickedData.mxid,
      sessionid: lastLinkClickedData.sessionid,
      doc_id: lastLinkClickedData.sessionid,
      mcc: lastLinkClickedData.doc.mcc,
      mobile: lastLinkClickedData.doc.mobile,
      category: lastLinkClickedData.data.coverage.category,
      url: "https://sample.diro.me",
      cvd: true,
      hashalgorithm: "md5",
      lang: "en",
    };

    const formData = new FormData();

    for (var i = 0; i < allFiles.length; i++) {
      var mhtmlFileName = allFiles[i].name;
      
      var mhtmlb64 = await PdfToBase64(allFiles[i]);
      
      var blob = b64toBlob(mhtmlb64, "");
      var file = blobToFile(blob, mhtmlFileName);
      
      formData.append("downloadedfiles", file, mhtmlFileName);
    }
    
    formData.append(
      "data",
      new Blob([JSON.stringify(oneShotPayload)], {
        type: "application/json",
      })
    );

    const liveFeedbackData = {};

    

    for (let i = 0; i < allLiveFeedbackResponses.length; i++) {
      console.log(
        "live feedback console",
        (liveFeedbackData[allFiles[i]?.name] = allLiveFeedbackResponses[i])
      );
      liveFeedbackData[allFiles[i]?.name] = allLiveFeedbackResponses[i];
    }

    formData.append("liveFeedBackData", JSON.stringify(liveFeedbackData));
    const Headers = {
      "Content-Type": "multipart/form-data",
    };

    try {
      dispatch({ type: ONESHOTUPLOAD_START });
      for (const value of formData.values()) {
        
      }

      const response = await axios.post(
        environment.oneshotupload,
        formData,
        Headers
      );
      // 
      var engagementCallbackpayload = {
        sessionid: lastLinkClickedData.sessionid,
      };
      const engagementCallbackResponse = await axios.post(
        environment.engagementCallback,
        engagementCallbackpayload
      );
      
      dispatch({ type: ONESHOTUPLOAD_SUCCESS, payload: response.data });
      sendLogs(
        "OneShotUpload",
        "OneShotUpload is successfully Done!",
        "uploading_done.template.js",
        lastLinkClickedData.sessionid,
        "5"
      );
      dispatch(updateSession(uploadDoneSession, lastLinkClickedData.sessionid));
    } catch (error) {
      
      dispatch({
        type: ONESHOTUPLOAD_FAILURE,
        payload: error?.response?.data,
      });
      dispatch({ type: SOMETHING_WENT_WRONG, payload: true });
      sendLogs(
        "OneShotUpload",
        `Error while OneShotUpload!:${error?.response?.data} `,
        "uploading_done.template.js",
        lastLinkClickedData.sessionid,
        "5"
      );
    }
  };

  return (
    <>
      {
      (isVerifying || processingLivefeedback || processingOneShotUpload || !lastLinkClickedData) &&
        !isUnsupportedMedia &&
        !passwordProtected &&
        !somethingWentWrong &&  <PleaseWait />}

      {(buttonIdIsNull || errorInVerificationPoc) && (
        <ButtonIdNotFound errorInVerificationPoc={errorInVerificationPoc} />
      )}

      {!buttonIdIsNull &&
        !errorInVerificationPoc &&
        !livefeedbackInvalidDoc &&
        !isVerifying &&
        !(documentIsVerified || documentIsNotOriginal) &&
        !documentIsWrong &&
        !isUnsupportedMedia &&
        !somethingWentWrong &&
        lastLinkClickedData &&
         (
          <Upload
            handleBrowseClick={handleBrowseClick}
            handleDragOver={handleDragOver}
            handleDrop={handleDrop}
            handleFileSelect={handleFileSelect}
            fileInputRef={fileInputRef}
          />
        )}

      {console.log(
        "uploadStataus check",
        !isVerifying,
        !documentIsVerified && !documentIsNotOriginal,
        !isUnsupportedMedia,
        !fileAlreadyExists,
        !processingLivefeedback,
        !processingOneShotUpload,
        !livefeedbackInvalidDoc,
        oneShotUploadSuccessResponse?.error !== false
      )}

      {!isVerifying &&
        (documentIsVerified || documentIsNotOriginal) &&
        !isUnsupportedMedia &&
        !fileAlreadyExists &&
        !processingLivefeedback &&
        !processingOneShotUpload &&
        !livefeedbackInvalidDoc &&
        !isUnsupportedMedia &&
        !livefeedbackInvalidDoc &&
        oneShotUploadSuccessResponse?.error !== false &&
        !somethingWentWrong &&
        !passwordProtected && (
          <UploadStatus
            handleBrowseClick={handleBrowseClick}
            handleDragOver={handleDragOver}
            handleDrop={handleDrop}
            handleFileSelect={handleFileSelect}
            fileInputRef={fileInputRef}
            oneShotUploadAction={oneShotUploadAction}
            allFiles={allFiles}
            setAllFiles={setAllFiles}
          />
        )}

      {!isVerifying && livefeedbackInvalidDoc && (
        <WrongDocument allFiles={allFiles} setAllFiles={setAllFiles} />
      )}

      {!isVerifying && somethingWentWrong && (
        <SomethingWentWrong allFiles={allFiles} setAllFiles={setAllFiles} />
      )}

      {!isVerifying && (isUnsupportedMedia || fileAlreadyExists) && (
        <UnsupportedMedia
          fileAlreadyExists={fileAlreadyExists}
          allFiles={allFiles}
          setAllFiles={setAllFiles}
        />
      )}

      {!isVerifying && passwordProtected && (
        <PasswordProtected allFiles={allFiles} setAllFiles={setAllFiles} />
      )}

      {!isVerifying &&
        !processingLivefeedback &&
        !processingOneShotUpload &&
        oneShotUploadSuccessResponse?.error === false && <ThankYouModal />}
    </>
  );
}
