import React, { useState } from "react";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, ModalBody } from "reactstrap";
import shoppingCartImg from "../Components/assets/shopping-cart-svgrepo-com.svg";
import LiveFeedback from "./view/LiveFeedbackPopup";
import { ALL_LIVEFEEDBACK_DATA } from "../actions/actionTypes";
import doneIcon from "../images/ic_done2.png";
import errorIcon from "../images/ic_error.png";
import check from "../images/check.png";
import diroLogo from "./assets/dirologo.svg";

export default function UploadStatus({
  handleBrowseClick,
  handleDragOver,
  handleDrop,
  handleFileSelect,
  fileInputRef,
  oneShotUploadAction,
  allFiles,
  setAllFiles,
}) {
  const dispatch = useDispatch();

  const [loaderValue, setLoaderValue] = useState(0);

  const lastLinkClickedData = useSelector(
    (state) => state.lastLinkClicked?.dataSuccess
  );

  // Get already saved liveFeedback responses from store.
  const allLiveFeedbackResponses = useSelector(
    (state) => state.livefeedback?.allLivefeedbackResponses
  );

  const documentIsNotOriginal = useSelector(
    (state) => state?.upload?.documentIsNotOriginal
  );

  const handleSubmit = () => {
    oneShotUploadAction();
  };

  // Function to handle click of try another button and to delete the file and the liveFeedback data of the selected file and to select another file.
  const handleTryAnother = () => {
    setAllFiles([]);
    dispatch({ type: ALL_LIVEFEEDBACK_DATA, payload: [] });
    handleBrowseClick();
  };

  // check even if a single document is invalid
  const checkEvenIfADocIsInvalid = () => {
    if (
      allLiveFeedbackResponses.find((item) =>
        item.document_type.toLowerCase().includes("Invalid".toLowerCase())
      )
    ) {
      return true;
    }
    return false;
  };
  

  const verifiedData = useSelector((state) => state.upload?.uploadedFile);

  const data = [
    { field: "Document ID", key: "docid" },
    { field: "Generated On", key: "generatedon" },
    { field: "Document fingerprint", key: "hash" },
    { field: "Original Source", key: "originalsource" },
  ];

  
  return (
    <Modal
      isOpen={true}
      className={"modal-centered"}
      backdropClassName="custom-backdrop"
      keyboard={false}
      backdrop={true}
      size="md"
      scrollable={true}
      contentClassName = "modal-content-style"
      style={{
        marginInline: "auto",
        minWidth: !documentIsNotOriginal ? "950px" : "auto",
      }}
    >
      <ModalBody className=" model-body">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            flexWrap: "nowrap",
            alignItems: "center",
          }}
        >
          <div>
            {!documentIsNotOriginal && verifiedData && !verifiedData?.error &&(
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <img src={doneIcon} className="verified-res-img" />
                <h5
                  className="text-red mb-4 fw-bolder ws-normal-m"
                  style={{
                    fontSize: "22px",
                    fontFamily: "system-ui",
                    fontWeight: "bolder",
                    lineHeight: "2rem",
                  }}
                >
                  The original document is successfully verified!
                </h5>

                <div>
                  {verifiedData && !verifiedData?.error &&
                    data?.map((item, index) => {
                      return (
                        <div className="verified-res-fields" key={index}>
                          <div
                            className="verified-res-field-title"
                            style={{
                              fontSize: "14px",
                              fontWeight: 600,
                              fontFamily: "Montserrat",
                            }}
                          >
                            {item.field}:
                          </div>
                          <p
                            className="verified-res-field-value"
                            style={{
                              fontSize: "14px",
                              fontWeight: "600",
                              // fontFamily: "Montserrat",
                              fontFamily: "Montserrat",
                            }}
                          >
                            {item?.key === "generatedon"
                              ? String(
                                  new Date(
                                    parseInt(verifiedData?.data[item?.key])
                                  )
                                )
                              : verifiedData?.data[item?.key]}
                          </p>
                        </div>
                      );
                    })}
                </div>
              </div>
            )}
          </div>
          <div
            style={{
              // width: 130,
              // height: 130,
              // margin: "20px auto",
              display: documentIsNotOriginal ? "block" : "none",
            }}
          >
            {lastLinkClickedData?.button?.multidownload ? (
              <CircularProgressbarWithChildren
                value={loaderValue}
                styles={buildStyles({
                  pathColor: "green",
                  // trailColor: "gold",
                })}
              >
                <img height={100} src={shoppingCartImg} alt="cart" />
              </CircularProgressbarWithChildren>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                  alignItems: "center",
                }}
              >
                <img src={check} height={40} width={40} />
                <div style={{ fontSize: "20px", fontWeight: "600" }}>
                  Document uploaded
                </div>
              </div>
            )}
          </div>
          <div
            style={{
              width: "100%",
              margin: "20px 0px",
              paddingLeft: "20px",
              display: documentIsNotOriginal ? "block" : "none",
            }}
          >
            <LiveFeedback
              setLoaderValue={setLoaderValue}
              allFiles={allFiles}
              setAllFiles={setAllFiles}
            />
          </div>
          <div style={{ display: "flex", gap: "20px" }}>
            {lastLinkClickedData?.button?.multidownload && (
              <Button
                className="btn cbtn"
                style={{
                  // borderRadius: "35px",
                  fontFamily: "Montserrat",
                  // border: "solid 2px #00bcd4",
                  backgroundColor: "black",
                  color: "#fff",
                  padding: "10px",
                  fontSize: "14px",
                  fontWeight: "600",
                  // width: "100%",
                  margin: "1rem 0rem 0rem 0rem",
                  width: "125px",
                }}
                onClick={handleBrowseClick}
              >
                {allFiles?.length > 0 ? "upload more" : "upload"}
              </Button>
            )}

            {!lastLinkClickedData?.button?.multidownload && (
              <Button
                className="btn cbtn"
                style={{
                  // borderRadius: "35px",
                  fontFamily: "Montserrat",
                  // border: "solid 2px #00bcd4",
                  backgroundColor: "white",
                  color: "black",
                  padding: "10px",
                  fontSize: "14px",
                  fontWeight: 600,
                  // width: "100%",
                  margin: "1rem 0rem 0rem 0rem",
                  // display: !allFiles?.length ? "none" : null,
                  width: "125px",
                  border: "2px solid black",
                  // display: (verifiedData && !verifiedData.error) ? "block" : "none",
                }}
                onClick={handleTryAnother}
              >
                Try another
              </Button>
            )}
            <input
              ref={fileInputRef}
              className="d-none"
              type="file"
              onChange={handleFileSelect}
              // multiple={lastLinkClickedData?.button?.multidownload}
            />

            <Button
              className="btn cbtn"
              style={{
                // borderRadius: "35px",
                fontFamily: "Montserrat",
                // border: "solid 2px #00bcd4",
                backgroundColor: "black",
                color: "#fff",
                padding: "10px",
                fontSize: "14px",
                fontWeight: "600",
                // width: "100%",
                margin: "1rem 0rem 0rem 0rem",
                display: documentIsNotOriginal ? "block" : "none",
                width: "125px",
              }}
              onClick={handleSubmit}
              disabled={!allFiles?.length}
            >
              {checkEvenIfADocIsInvalid() ? "Submit anyway" : "Submit"}
            </Button>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            marginTop: "10px",
          }}
        >
          <div
            style={{
              fontSize: "14px",
              fontWeight: 600,
            }}
          >
            Powered by
          </div>
          <img src={diroLogo} height={13} />
        </div>
      </ModalBody>
    </Modal>
  );
}
