import React, { useState, useRef, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import "./statementUploader.css";
import { Modal, ModalBody, Button } from "reactstrap";
import "./statementUploader.css";
import { DOCUMENT_NOT_ORIGINAL } from "../../actions/actionTypes";
import { useDispatch } from "react-redux";

const TechnicalErrorIconSvg = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="70"
    height="70"
    viewBox="0 0 24 21"
  >
    <path
      fill="#FDB828"
      fillRule="nonzero"
      d="M23.344 17.47l-9.282-15.9c-1.135-1.944-2.989-1.944-4.124 0L.655 17.47C-.48 19.412.451 21 2.72 21H21.28c2.268 0 3.195-1.587 2.063-3.53zM10.724 6.08c.335-.357.757-.535 1.276-.535.52 0 .941.175 1.276.528.329.35.498.792.498 1.323 0 .453-.692 3.806-.923 6.242h-1.67c-.201-2.436-.955-5.789-.955-6.242 0-.524.17-.963.498-1.316zm2.527 11.591A1.75 1.75 0 0 1 12 18.18a1.75 1.75 0 0 1-1.251-.507 1.64 1.64 0 0 1-.523-1.227c0-.477.173-.891.523-1.237.35-.346.768-.517 1.251-.517.483 0 .901.17 1.251.517.35.346.523.76.523 1.237 0 .482-.173.889-.523 1.227z"
    />
  </svg>
);

var DocumentNotOriginal = ({ setTryAgain, modalColor, PocError }) => {
  const dispatch = useDispatch();

  return (
    <Modal
      isOpen={true}
      className={"modal-centered"}
      backdropClassName="custom-backdrop"
      keyboard={false}
      backdrop={true}
      size="md"
      scrollable={true}
    >
      <ModalBody className=" model-body">
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            flexDirection: "column",
            alignContent: "stretch",
            flexWrap: "nowrap",
            alignItems: "center",
          }}
        >
          <div className="mt-3 ">
            <TechnicalErrorIconSvg />
          </div>
          <div>
            {" "}
            <h2
              style={{
                fontFamily: "",
                marginTop: "2rem",
                width: "20rem",
                textAlign: "center",
                fontWeight: "700",
              }}
            >
              This document is not original
            </h2>
          </div>
          {/* <h5
              className="see-preview"
              style={{
                marginTop: "1rem",
                textAlign: "center",
                fontWeight: "600",
              }}
            >
              See preview
            </h5> */}
          <Button
            className="btn cbtn"
            id="browse"
            style={{
              borderRadius: "35px",
              fontFamily: "Montserrat",
              // border: "solid 2px #00bcd4",
              backgroundColor: "black",
              color: "#fff",
              padding: "10px 20px",
              fontSize: "14px",
              fontWeight: "600",
              // width: "100%",
              margin: "1rem 0rem 2rem 0rem",
            }}
            onClick={() =>
              dispatch({ type: DOCUMENT_NOT_ORIGINAL, payload: false })
            }
          >
            Try Again
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default DocumentNotOriginal;
