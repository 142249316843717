import React from "react";
import {useSelector} from 'react-redux';
import { Modal, ModalBody } from "reactstrap";
import "./indexTemplate.css";
import "../../index.css";
import './indexTemplate.css'

const Verifying = ({ modalColor }) => {
  const lastLinkedClickedData = useSelector(state => state?.lastLinkClicked?.dataSuccess)
  const sessionId = lastLinkedClickedData?.inviteid
  return (
    <Modal
      isOpen={true}
      className="modal-centered flex "
      style={{
        fontFamily: "Montserrat",
        "--modal-color": modalColor,
        maxWidth: "25rem"
      }}
      keyboard={false}
      backdrop={true}
      backdropClassName="custom-backdrop"
    >
      <ModalBody className="text-center">
        <div className="loading mt-3 mb-3">
          <div className="lds-ellipsis">
            <div
              style={{
                background: modalColor
                  ? "var(--modal-color)"
                  : "var(--default-color)",
              }}
            ></div>
            <div
              style={{
                background: modalColor
                  ? "var(--modal-color)"
                  : "var(--default-color)",
              }}
            ></div>
            <div
              style={{
                background: modalColor
                  ? "var(--modal-color)"
                  : "var(--default-color)",
              }}
            ></div>
            <div
              style={{
                background: modalColor
                  ? "var(--modal-color)"
                  : "var(--default-color)",
              }}
            ></div>
          </div>
        </div>
        <h5
          className="mb-3 fw-bold "
          style={{ color: modalColor, lineHeight: "1", letterSpacing: "2px" }}
        >
          Verifying...
        </h5>
      </ModalBody>
      <p style={{marginLeft:"85%",fontSize:"10px",marginTop:"1rem",marginBottom:"10px", width:"5rem",marginRight:"10rem"}}>{sessionId}</p>
    </Modal>
  );
};

export default Verifying;
