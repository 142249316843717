import React from "react";
import { Modal, ModalBody } from "reactstrap";
import "./indexTemplate.css";
import "../../index.css";

const PleaseWait = ({ modalColor }) => {
  return (
    <Modal
      isOpen={true}
      className="modal-centered flex "
      style={{
        fontFamily: "Montserrat",
        "--modal-color": modalColor,
        maxWidth: "25rem",
        marginTop: "0px",
        marginBottom: "0px",
      }}
      keyboard={false}
      backdrop={true}
      backdropClassName="custom-backdrop"
      contentClassName="modal-content-style"
    >
      <ModalBody className="text-center">
        <div className="loading mt-3 mb-3">
          <div className="lds-ellipsis">
            <div
              style={{
                background: modalColor
                  ? "var(--modal-color)"
                  : "var(--default-color)",
              }}
            ></div>
            <div
              style={{
                background: modalColor
                  ? "var(--modal-color)"
                  : "var(--default-color)",
              }}
            ></div>
            <div
              style={{
                background: modalColor
                  ? "var(--modal-color)"
                  : "var(--default-color)",
              }}
            ></div>
            <div
              style={{
                background: modalColor
                  ? "var(--modal-color)"
                  : "var(--default-color)",
              }}
            ></div>
          </div>
        </div>
        <h5
          className="mb-5 fw-bold "
          style={{
            color: modalColor,
            lineHeight: "1",
            letterSpacing: "2px",
            fontFamily: "Montserrat",
            fontSize: "20px",
            fontWeight: "600",
          }}
        >
          Please wait...
        </h5>
      </ModalBody>
    </Modal>
  );
};

export default PleaseWait;
