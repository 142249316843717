import React,{useState} from "react";
import { Modal, ModalBody, Button,Nav,NavItem,NavLink } from "reactstrap";
import { useDispatch, useSelector } from "react-redux"; // Import useDispatch
import axios from "axios";
import { env as environment } from "../../environments";
import { b64toBlob, blobToFile } from "../../helpers/pdfFileChangerHandler";
import {
  ONESHOTUPLOAD_START,
  ONESHOTUPLOAD_SUCCESS,
  ONESHOTUPLOAD_FAILURE,
} from "../../actions/actionTypes";
import "./indexTemplate.css";
import "../../index.css";
import { sendLogs } from "../../helpers/sendLogs";
import {updateSession} from '../../helpers/updateSessionHandler'

const ArrowIconSvg = ({ color }) => (
<svg width="50" height="30" viewBox="0 0 36 16" fill={color} stroke={color} xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" stroke={color} d="M28.546 0.697527L34.8185 7.1517C35.2034 7.54776 35.1944 8.18086 34.7983 8.56577L28.3441 14.8382C27.9481 15.2231 27.315 15.2141 26.9301 14.818C26.5451 14.422 26.5542 13.7889 26.9502 13.404L31.6731 8.81407L0.0905462 8.36311L0.119101 6.36331L31.7017 6.81427L27.1118 2.09141C26.7269 1.69534 26.7359 1.06224 27.132 0.677336C27.528 0.292427 28.1611 0.301467 28.546 0.697527Z" fill={color}/>
</svg>
)

const CheckIconCircleSvg = ({ color }) => (
  <svg
    style={{ margin: "1rem auto", width: "3.5rem", height: "4rem" }}
    viewBox="0 0 28 24"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="13.5" cy="12.5" r="12.5" fill={color} />
    <path
      d="M16.9536 7.37582C17.1761 7.13761 17.4785 7.0025 17.7947 7.00003C18.1109 6.99756 18.4152 7.12793 18.641 7.36264C18.8668 7.59735 18.9959 7.91731 18.9999 8.25261C19.0039 8.58791 18.8827 8.91127 18.6626 9.15203L12.2944 17.5946C12.185 17.7196 12.053 17.8198 11.9063 17.8894C11.7595 17.959 11.6011 17.9966 11.4403 17.9998C11.2796 18.003 11.1199 17.9718 10.9708 17.9081C10.8217 17.8445 10.6862 17.7496 10.5724 17.6291L6.35029 13.1533C6.12593 12.9152 5.99992 12.5924 6 12.2558C6.00008 11.9192 6.12623 11.5965 6.3507 11.3585C6.57517 11.1206 6.87958 10.987 7.19696 10.987C7.51434 10.9871 7.81869 11.1209 8.04305 11.3589L11.3823 14.901L16.9219 7.41544C16.932 7.40182 16.9428 7.38888 16.9544 7.37668L16.9536 7.37582Z"
      fill="white"
    />
  </svg>
);


const UploadingDone = ({ modalColor }) => {
  const dispatch = useDispatch(); // Initialize dispatch
  const lastLinkedClicked = useSelector(
    (state) => state.lastLinkClicked.dataSuccess
  );
  const LiveFeedBackData = useSelector(
    (state) => state.livefeedback.feedbackSuccess?.response
  );
  const [showTemplate, setShowTemplate] = useState(true);
  const pdfBase64Data = useSelector((state) => state.pdfData.data);
  const blobdata = b64toBlob(pdfBase64Data);
  const lastLinkedClickedData = useSelector(state => state?.lastLinkClicked?.dataSuccess)
  const sessionId = lastLinkedClickedData?.inviteid
  var uploadDoneSession = {
    sessionid:sessionId,
    data: {
      indicator: {
        progress_no: "8",
        progress_name: "Done, now in Review ",
        Detection: "upload",
      },
    },
  };


  var userExitSessionUpdate = {
    sessionid:sessionId,
    data: {
      indicator: {
        progress_no: "10",
        progress_name: "User Exit",
        Detection: "upload",
      },
    },
  };
  
  
  const HandleCancel = ()=>{
    dispatch(updateSession(userExitSessionUpdate,sessionId))
    setShowTemplate(false);
  }
  
  const oneShotUploadAction = async (dataObject, blob) => {
    const oneShotPayload = {
      mxid: dataObject.mxid,
      sessionid: dataObject.sessionid,
      doc_id: dataObject.sessionid,
      mcc: dataObject.doc.mcc,
      mobile: dataObject.doc.mobile,
      category: dataObject.data.coverage.category,
      url: "https://sample.diro.me",
      cvd: true,
      hashalgorithm: "md5",
      lang: "en",
    };

    const formData = new FormData();
    var file = blobToFile(blob, "example.pdf");

    formData.append(
      "data",
      new Blob([JSON.stringify(oneShotPayload)], {
        type: "application/json",
      })
    );

    

    formData.append("downloadedfiles", file, "example.pdf"); // Append file to the same key
    const Headers = {
      "Content-Type": "multipart/form-data",
    };

    try {
      dispatch({ type: ONESHOTUPLOAD_START });
      const response = await axios.post(
        environment.oneshotupload,
        formData,
        Headers
      );
      // 
      var engagementCallbackpayload= {
        sessionid:sessionId
      }
      const engagementCallbackResponse = await axios.post(environment.engagementCallback,engagementCallbackpayload)
      
      dispatch({ type: ONESHOTUPLOAD_SUCCESS, payload: response.data });
      sendLogs('OneShotUpload',"OneShotUpload is successfully Done!","uploading_done.template.js",dataObject.sessionid,"5")
      dispatch(updateSession(uploadDoneSession,sessionId))
     
    } catch (error) {
      
      dispatch({
        type: ONESHOTUPLOAD_FAILURE,
        payload: error.response.data,
      });
      sendLogs('OneShotUpload',`Error while OneShotUpload!:${error.response.data} `,"uploading_done.template.js",dataObject.sessionid,"5")
    }
  };

  return (
    ( showTemplate && (
      <>
    <Modal
      isOpen={true}
      className={`modal-centered`}
      style={{ fontFamily: "Montserrat", "--modal-color": modalColor }}
      keyboard={false}
      backdrop={true}
      size="md"
      scrollable={true}
      backdropClassName="custom-backdrop"
      backdrop-filter="saturate(176%) blur(8px)"
    >

      <ModalBody className="text-center">
        <CheckIconCircleSvg
          color={
            modalColor ? "var(--modal-color)"
            : "var(--default-color)"
          }
        />
        <h5
          className="text-red mb-5 fw-bolder"
          style={{ color: "var(--modal-color)" }}
        >
          Document uploaded
        </h5>
        {(lastLinkedClicked.data?.livefeedbackMode && lastLinkedClicked.data?.sharerequestedfieldsonly && LiveFeedBackData.document_type !== "Invalid") && (
          <div style={{marginBottom:"2rem",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start"
      }}>
            <ul style={{width:"10rem"}}>
              <h5 style={{
                fontSize: "1rem",
                fontWeight: 700,
                marginLeft:"1rem",
                color: modalColor
                  ? "var(--modal-color)"
                  : "var(--default-checkSvg-color)",
                fontFamily:"system-ui"

              }}>Submit only :</h5>
              {Object.keys(LiveFeedBackData).map((key, index) => {
                if (key !== "document_type" && key !== "period") {
                  return (
                    <li style={{textAlign:"start",marginLeft:"2rem", width: "10rem",color: modalColor
                    ? "var(--modal-color)"
                    : "var(--default-checkSvg-color)" ,}}  key={index}>
                      {/* {key.charAt(0).toUpperCase() + key.slice(1)}  */}
                      {key === 'accountnumber' ? 'Account Number' : key.charAt(0).toUpperCase() + key.slice(1)}
                    </li>
                  );
                }
                return null;
              })}
            </ul>
            <div style={{margin:"2rem 2rem 2rem 2rem"}}>
            <ArrowIconSvg modelColor={ modalColor ? "var(--modal-color)"
            : "var(--default-color)"} />
            </div>
            <div className="logocontainer">
            <img src={lastLinkedClicked.orglogo} style={{width:"60px", height:"60px", background:"transparent"}} alt="logo" />
            </div>
          </div>
        )}

        <div className="btn-upload">
          <a>
            <Button
              className="upload-button mb-3"
              style={{
                background: "transparent",
                color: modalColor
                  ? "var(--modal-color)"
                  : "var(--default-color)",
                border: "2px solid",
                fontWeight: "bolder",   
              }}
              onClick={HandleCancel}
            >
              Cancel
            </Button>
          </a>
          <Button
            className="upload-button"
            style={{
              backgroundColor: modalColor
                ? "var(--modal-color)"
                : "var(--default-color)",
              border: "2px solid",
              fontWeight: "bolder",
            }}
            onClick={(event) => {
              event.preventDefault(); // Prevent default form submission
              oneShotUploadAction(lastLinkedClicked, blobdata); // Call the action function
            }}
          >
            Submit
          </Button>
        </div>
      </ModalBody>
      <p style={{marginLeft:"85%",fontSize:"10px",marginTop:"1rem",marginBottom:"10px", width:"5rem",marginRight:"10rem"}}>{sessionId}</p>
    </Modal>
    </>
  ))
  );
};

export default UploadingDone;
